import _ from 'lodash'
import { boot } from 'quasar/wrappers'
import BootMixins from 'mixins/boot'
import RolesMixins from 'hc-core/mixins/roles.js'
import GlobalMixins from 'hc-core/mixins/global.js'
import { intlInit } from 'hc-core/composables/intl'
import { useSentry } from 'hc-core/composables/sentry'
import HCButton from 'hc-core/components/buttons/hc-button'
import HCLoading from 'hc-core/components/common/hc-loading'
import AppAvatar from 'hc-core/components/common/app-avatar'
import AppContent from 'hc-core/components/common/app-content'
import AccountCard from 'hc-core/components/cards/account-card'
import HCBigButton from 'hc-core/components/buttons/hc-big-button'
import ActionTooltip from 'hc-core/components/tooltips/action-tooltip'
import GalleryManager from 'hc-core/components/tools/gallery-manager'
import GridMediaManager from 'hc-core/components/tools/grid-media-manager'
import MediaAlbumDialog from 'hc-core/components/dialogs/media-album-dialog'

export default boot(async ({ app, store }) => {
  if (process.env.HC_INSTANCE === 'EC2') useSentry(app)

  // Components
  app.config.devtools = true
  app.component('HCButton', HCButton)
  app.component('HCLoading', HCLoading)
  app.component('AppAvatar', AppAvatar)
  app.component('AppContent', AppContent)
  app.component('AccountCard', AccountCard)
  app.component('HCBigButton', HCBigButton)
  app.component('ActionTooltip', ActionTooltip)

  // Components - Global import needed since circular reference
  app.component('GalleryManager', GalleryManager)
  app.component('GridMediaManager', GridMediaManager) // Still used in COmpanyAttributeSelector
  app.component('MediaAlbumDialog', MediaAlbumDialog)

  // Mixins
  app.mixin(BootMixins)
  app.mixin(RolesMixins)
  app.mixin(GlobalMixins)

  // Prototypes
  app.config.globalProperties.$_ = _
  app.config.globalProperties.$uElements = store.getters['auth/uElements']

  // Intl setup
  await intlInit({ app, store })
})
