import _ from 'lodash'
import axios from 'axios'
import stelace, { fetchAllResultsChunks } from 'hc-core/composables/stelace'
import {
  getCurrentUserId,
  createCookieInstance,
  shouldAuthenticateAsOrg,
} from 'hc-core/composables/auth'

const stelaceInstance = axios.create({
  baseURL: process.env.STELACE_API_URL,
  headers: {
    authorization: 'Stelace-V1 apiKey=' + process.env.STELACE_PUBLISHABLE_API_KEY
  }
})

export async function checkAvailability (context, { username }) {
  const res = await stelace.users.checkAvailability({ username })
  return res.available
}

export async function fetchCurrentUser ({ state, commit, dispatch, rootGetters }, { forceRefresh = false } = {}) {
  try {
    // const isBrowser = typeof window === 'object'
    // if (!isBrowser) return null

    const currentUser = state.currentUser || {}
    let naturalUser = state.currentNaturalUser || {}
    const userId = getCurrentUserId()
    if (!forceRefresh && naturalUser.id === userId) return currentUser
    if (!userId || currentUser === {}) return null

    naturalUser = await stelace.users.read(userId, { stelaceOrganizationId: null })
    commit('auth/setCurrentNaturalUser', { user: naturalUser }, { root: true })
    const entities = await dispatch('auth/getUserOrganizations', { forceRefresh: true, userId, fields: ['companies', 'organizations', 'implantations', 'profileAsset', 'stats'] }, { root: true })
    const organizations = entities.organizations
    commit('auth/setOrganizations', { organizations }, { root: true })

    const config = rootGetters['common/getConfig']
    const authenticateAsOrg = shouldAuthenticateAsOrg(naturalUser, config)
    if (!authenticateAsOrg) {
      if (!Object.keys(currentUser).length && naturalUser !== {}) commit('auth/setCurrentUser', { user: naturalUser }, { root: true })
      else commit('auth/setCurrentUser', { user: currentUser }, { root: true })
      await dispatch('auth/getHcPerms', undefined, { root: true })
      return naturalUser
    }

    const sortedOrganizations = _.sortBy(organizations, org => org.createdDate)
    const implantationsOrganizations = _.reject(sortedOrganizations, function (org) { return org.metadata.type !== 'implantation' })
    const firstOrg = implantationsOrganizations[0]

    const selectedOrganizationId = createCookieInstance().getOrganizationIdFromCookie()
    const selectedOrg = selectedOrganizationId
      ? organizations ? organizations.find(org => org.id === selectedOrganizationId)
        : null : null

    if (selectedOrg || firstOrg) {
      let organizationId
      if (selectedOrg) {
        organizationId = selectedOrg.id
      } else {
        organizationId = firstOrg.id
      }

      await dispatch('auth/selectOrganization', { organizationId }, { root: true })
    } else {
      commit('auth/setCurrentUser', { user: naturalUser }, { root: true })
    }
    return naturalUser
  } catch (e) {}
}

export async function selectOrganization ({ state, commit, dispatch }, { organizationId }) {
  const organization = state.organizationsById[organizationId]
  if (!organization) return

  commit('auth/setCurrentUser', { user: organization }, { root: true })
  const company = organization.company ?? _.get(state, 'userOrganizations.companies', []).find(({ ownerId }) => organization.id === ownerId) ?? null
  if (company) commit({ type: 'auth/setUserCompany', company }, { root: true })

  stelace.setOrganizationId(organizationId)
  createCookieInstance().setOrganizationIdInCookie(organizationId)
  // await dispatch('auth/getHcPerms', undefined, { root: true })
}

export async function signup ({ dispatch }, { user, noLogin = false }) {
  if (user.email) {
    _.set(user, 'email', user.email.toLowerCase())
    _.set(user, 'username', user.email)
  }
  if (user.username) user.username = user.username.toLowerCase()
  if (_.get(user, 'firstname', false) && _.get(user, 'lastname', false)) {
    _.set(user, 'displayName', `${user.firstname} ${user.lastname[0]}.`)
  }
  let stlUser = await stelace.users.create(_.omit(user, ['metadata', 'platformData']))

  // add timeout to background operations to be completed on the new user
  await new Promise(resolve => setTimeout(resolve, 2000))
  if (noLogin) return stlUser

  createCookieInstance().handleHappyCookie({ value: await stelace.auth.login({ username: user.username, password: user.password }) })
  await dispatch('auth/fetchCurrentUser', undefined, { root: true })

  // Then send metadata and platformData since permissions on namespaces are here
  stlUser = await stelace.users.update(stlUser.id, _.pick(user, ['metadata', 'platformData']))

  await stelaceInstance.post('/events', {
    type: 'user_login',
    objectId: stlUser.id,
    emitterId: 'happycab-v3'
  })
  return stlUser
}

// Mainly used
export async function getUserOrganizations ({ commit, rootGetters, state, dispatch }, {
  userId,
  mutate = true,
  forceRefresh = true,
  cache = 10,
  fields = ['organizations', 'implantations', 'companies', 'offers', 'templates', 'questionnaires', 'ratings', 'messages', 'applications', 'applicants', 'users', 'applicantList', 'profileAsset', 'subscription', 'slots', 'offerCredits', 'addressCredits', 'cvthequeCredits', 'perks', 'rootUser', 'targetId'],
  onlyFetchIfEmpty = false // Default to false to not break already existing, but better if at term it's set to true
} = {}) {
  let data = {}

  try {
    // TODO : only trigger fatching if not already in process - based on userOrganizations.loading
    commit('auth/setUserOrganizations', { userOrganizations: { loading: fields } }, { root: true })
    const now = new Date().getTime()
    const cacheExpired = state.lastUpdate === null ? true : now > state.lastUpdate + (cache * 1000)
    if (!forceRefresh && (state.loading || !cacheExpired)) return
    userId = userId ?? rootGetters['auth/currentNaturalUser'].id
    if (!getCurrentUserId()) {
      await dispatch('auth/logout', undefined, { root: true })
      return
    }

    // Only fetch if empty for requested fields
    const existingData = _.pick(rootGetters['auth/userOrganizations'], fields)
    const hasDataLength = Object.values(existingData).reduce((acc, field) => {
      return acc && _.get(Array.isArray(field) ? field : Object.keys(field), 'length', 0)
    }, true)
    if (hasDataLength && onlyFetchIfEmpty) return rootGetters['auth/userOrganizations']

    // Applications is required to feed applicants
    if (fields.includes('applicants') && !fields.includes('applications')) fields.push('applications')

    const entities = await stelace.forward.get(`/users/elements?id=${userId}`, { fields })
    data = _.merge(data, _.clone(entities))

    if (data.implantations) {
      for (const implantation of data.implantations) {
        implantation.company = _.get(_.filter(data.companies, (c) => c.ownerId === implantation.id), '[0]', null)
        if (implantation.company) implantation.name = implantation.company.name
        implantation.offers = _.filter(data.offers, (o) => o.ownerId === implantation.id)
        implantation.users = _.filter(data.users, (u) => u.organizations[implantation.id] !== undefined)
      }
    }
    if (data.companies) {
      for (const company of data.companies) {
        company.implantation = _.get(_.filter(entities.implantations, (i) => i.id === company.ownerId), '[0]', null)
      }
    }
    if (data.ratings) {
      for (let rating of data.ratings) {
        rating = _.merge(rating, _.clone(rating.data))
      }
    }

    // Fetch talk assets - only once, so not refetched when calling userOrgs
    if (!_.compact(Object.values(rootGetters['inbox/talkAssets'])).length) {
      const talkAssets = await dispatch('asset/list', { assetTypeId: rootGetters['common/talkAssetsTypeId'] }, { root: true })
      commit('inbox/setTalkAssets', { talkAssets }, { root: true })
    }

    // Ensure use of actual userCompany, instead of newest created
    const currentOwnedComp = _.get(data, 'companies', []).find(c => c.id === _.get(state, 'userCompany.id', null))
    if (currentOwnedComp) commit('auth/setUserCompany', { company: currentOwnedComp }, { root: true })
    else if (_.get(data, 'companies[0]', false)) commit('auth/setUserCompany', { company: data.companies[0] }, { root: true })
    data.plan = entities.plan || entities.subscription || null

    if (mutate) commit('auth/setUserOrganizations', { userOrganizations: data }, { root: true })

    // ###########################  INBOX PART #########################
    if (fields.includes('messages')) {
      // Fetch talk assets - only once, so not refetched when calling userOrgs
      if (!_.compact(Object.values(rootGetters['inbox/talkAssets'])).length) {
        const talkAssets = await dispatch('asset/list', { assetTypeId: rootGetters['common/talkAssetsTypeId'] }, { root: true })
        commit('inbox/setTalkAssets', { talkAssets }, { root: true })
      }

      // Sort messages by creation && conversations by newest first
      const messages = _.orderBy(rootGetters['auth/userOrganizations'].messages.filter((m) => !_.get(m, 'metadata.isHiddenMessage', false), 'createdDate', 'asc'))
      const timeSortedConversationsIds = _.uniq(_.orderBy(messages, 'createdDate', 'desc').map((m) => m.conversationId))
      let usersIds = _.uniq(_.concat(
        messages.map((m) => m.senderId),
        messages.map((m) => m.receiverId),
        rootGetters['auth/uElements']('applicants').map((app) => app.id))
      )

      // Map users by id
      // TODO: find other way than loop, maybe map or reduce or assign
      const usersById = {}
      // for (const applicant of rootGetters['auth/userOrganizations'].applicants) set(usersById, applicant.id, applicant)
      // for (const user of rootGetters['auth/userOrganizations'].users) set(usersById, user.id, user)
      // const currentUser = rootGetters['auth/currentUser']
      if (rootGetters['auth/userOrganizations'].subscription ?? false) usersIds.unshift(rootGetters['common/choId']) // , rootGetters['common/administrativeId']

      // Remove already fetched users from fetching
      const prefetchedUsers = _.concat(
        _.map(rootGetters['auth/userOrganizations'].users),
        _.map(rootGetters['auth/userOrganizations'].applicants),
        _.map(rootGetters['auth/userOrganizations'].organizations)
      )
      // Applicants : fetch org where they applied
      usersIds = _.concat(usersIds, rootGetters['auth/uElements']('applications').map(a => a.ownerId))

      usersIds = usersIds.filter(x => !_.map(prefetchedUsers, 'id').includes(x))

      const fetchUsersRequest = (...args) => stelace.users.list(...args)
      const remainingUsers = usersIds.length ? await fetchAllResultsChunks({ fetchFn: fetchUsersRequest, params: { id: _.uniq(usersIds) }, chunkKey: 'id' }) : []
      for (const user of _.concat(prefetchedUsers, remainingUsers)) _.set(usersById, user.id, user)

      // Map conversations by id
      let totalUnread = 0
      const conversationsById = {}
      for (const convId of timeSortedConversationsIds) {
        _.set(conversationsById, `${convId}.messages`, messages.filter((m) => m.conversationId === convId && !_.get(m, 'metadata.scheduledToSend', false)))
        _.set(conversationsById, `${convId}.scheduledToSend`, messages.filter((m) => m.conversationId === convId && _.get(m, 'metadata.scheduledToSend', false)))
        _.set(conversationsById, `${convId}.convId`, convId)
        const firstMessage = _.get(conversationsById, `${convId}.messages[0]`)
        if (firstMessage) {
          _.set(conversationsById, `${convId}.topicId`, firstMessage.topicId ? firstMessage.topicId : rootGetters['inbox/talkAssets'].userTopicId)

          // Complex
          const arr = Object.keys(rootGetters['auth/currentNaturalUser'].organizations)
          arr.push(rootGetters['auth/currentNaturalUser'].id)
          _.set(conversationsById, `${convId}.interlocutorId`, !arr.includes(firstMessage.senderId) ? firstMessage.senderId : firstMessage.receiverId)
        }

        // Unread numbers
        const tmpUnread = _.get(conversationsById, `${convId}.messages`, []).filter((m) => m.receiverId === getCurrentUserId() && !m.read).length
        _.set(conversationsById, `${convId}.nbUnread`, tmpUnread)
        totalUnread += tmpUnread
      }

      // Remove duplicates conversations , maybe not the best way
      const alreadyMerged = []
      for (const [convId, conv] of Object.entries(conversationsById)) {
        if (alreadyMerged.includes(convId)) continue
        const sameInterlocutorConvs = Object.values(conversationsById).filter(c => c.interlocutorId === conv.interlocutorId && c.convId !== convId)
        if (sameInterlocutorConvs.length) {
          for (const sameConv of sameInterlocutorConvs) {
            _.set(conv, 'messages', _.orderBy(_.uniq(_.concat(conv.messages, sameConv.messages), 'createdDate', 'desc')))
            _.set(conv, 'scheduledToSend', _.orderBy(_.concat(conv.scheduledToSend, sameConv.scheduledToSend), 'createdDate', 'desc'))
            _.set(conv, 'tmpUnread', conv.nbUnread + sameConv.nbUnread)
            alreadyMerged.push(sameConv.convId)
            timeSortedConversationsIds.splice(timeSortedConversationsIds.indexOf(sameConv.convId), 1)
          }
        }
      }

      commit('inbox/setInbox', { inbox: { messages, usersById, conversationsById, timeSortedConversationsIds, totalUnread } }, { root: true })
    }
    return data
  } catch (e) {
    console.error(e)
    return data
  } finally {
    commit('auth/setUserOrganizations', { userOrganizations: { loading: [] } }, { root: true })
  }
}

// Password related
export async function sendResetPasswordRequest (context, { username }) {
  return await stelace.password.resetRequest({ username })
}

export async function sendResetPasswordConfirmation (context, { resetToken, newPassword }) {
  await stelace.password.resetConfirm({ resetToken, newPassword })
}

export function selectNaturalUser ({ state, commit }) {
  const user = state.naturalUser
  if (!user) return
  commit('auth/setCurrentUser', { user }, { root: true })
  stelace.setOrganizationId(null)
}

export async function createToken (context, { userId = undefined, type = 'testimonial', expirationDate, data }) {
  return await stelace.tokens.checkRequest({ data, type, userId, expirationDate })
}

export async function checkToken ({ commit }, { token }) {
  const res = await stelaceInstance.get(`/token/check/${token}`)
  if (res.data.status === 'valid' || res.data.status === 'alreadyChecked') {
    commit('auth/setCheckoutData', { checkout: res.data }, { root: true })
  }
  return res.data
}

// ##### Session management #####

export async function login ({ dispatch }, { username, password }) {
  createCookieInstance().handleHappyCookie({ value: await stelace.auth.login({ username: username.toLowerCase(), password }) })
  const user = await dispatch('auth/fetchCurrentUser', undefined, { root: true })
  await stelace.events.create({ type: 'user_login', objectId: user.id, emitterId: 'happycab-v3' })

  // Update for lastActivity
  const profileAssetId = _.get(user, 'metadata._resume.profileAssetId')
  if (profileAssetId && user.roles.includes('applicant')) {
    await dispatch('asset/update', {
      assetId: profileAssetId,
      attrs: {
        customAttributes: {
          lastActivity: new Date().toISOString()
        }
      }
    })
  }
}

export async function loginAs (context, { token, ssrCookieInstance } = {}) {
  const checkToken = await stelace.auth.check({
    authorization: `Stelace-V1 apiKey=${process.env.STELACE_PUBLISHABLE_API_KEY}, token=${token.accessToken}`
  })
  const cInstance = ssrCookieInstance ?? createCookieInstance()
  if (checkToken.valid) cInstance.handleHappyCookie({ value: token })
  return checkToken.valid
}

export async function logout ({ commit, dispatch, state }, { sessionExpired = false, ssrCookieInstance = null } = {}) {
  try {
    // Update for lastActivity
    const profileAssetId = _.get(state.naturalUser, 'metadata._resume.profileAssetId')
    if (profileAssetId && state.naturalUser.roles.includes('applicant')) {
      await dispatch('asset/update', {
        assetId: profileAssetId,
        attrs: {
          customAttributes: { lastActivity: new Date().toISOString() }
        }
      })
    }

    const cInstance = ssrCookieInstance ?? createCookieInstance()
    if (!sessionExpired) await stelace.auth.logout({ refreshToken: cInstance.getHappyCookie('refreshToken') ?? 'null' })
    stelace.setOrganizationId(null)
    cInstance.logout()
    commit('auth/setCurrentUser', { user: null }, { root: true })
    commit('auth/setCurrentNaturalUser', { user: null }, { root: true })
    commit('auth/setOrganizations', { organizations: [] }, { root: true })
    commit('auth/setUserCompany', { user: null }, { root: true })
  } catch (error) { console.error(error) }
}

// ##### Permissions management #####

export async function getHcPerms ({ commit }) {
  const hcPerms = await stelace.forward.get('auth/hc-perms')
  commit('auth/setHcPerms', { hcPerms }, { root: true })
  return hcPerms
}
