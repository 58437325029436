import { get, set } from 'lodash'
import stelace from 'hc-core/composables/stelace'

// ##### Generic calls #####

export async function create ({ commit }, { attrs }) {
  const asset = await stelace.assets.create(attrs)
  commit('asset/setAsset', { asset }, { root: true })
  return asset
}

export async function read ({ commit }, { assetId }) {
  const asset = await stelace.assets.read(assetId)
  commit('asset/setAsset', { asset }, { root: true })
  return asset
}

export async function list (context, params) {
  return await stelace.assets.list(params)
}

export async function update ({ commit }, { assetId, attrs }) {
  const asset = await stelace.assets.update(assetId, attrs)
  commit('asset/setAsset', { asset }, { root: true })
  return asset
}

// Copied from quasar, need refactoring
export async function remove ({ rootState, dispatch }, { assetId }) {
  if (!assetId) return null
  const asset = await stelace.assets.read(assetId)
  if (!asset) return null
  const currentUserId = rootState.auth.user.id
  if (asset.ownerId && asset.ownerId.startsWith('org_')) dispatch('auth/selectOrganization', { organizationId: asset.ownerId }, { root: true })
  await stelace.assets.remove(assetId)
  if (currentUserId && currentUserId.startsWith('org_')) dispatch('auth/selectOrganization', { organizationId: currentUserId }, { root: true })
  return true
}

// ##### Specific calls #####

export async function createTransactionTransition ({ rootState, dispatch }, { transaction, transitionName, data }) {
  const currentUserId = rootState.auth.user.id
  if (transaction.ownerId && transaction.ownerId.startsWith('org_')) dispatch('auth/selectOrganization', { organizationId: transaction.ownerId }, { root: true })
  const result = await stelace.transactions.createTransition(transaction.id, { name: transitionName, data })
  if (currentUserId && currentUserId.startsWith('org_')) dispatch('auth/selectOrganization', { organizationId: currentUserId }, { root: true })
  return result
}

export async function updateTransaction ({ rootState, dispatch }, { transaction, attrs }) {
  const currentUserId = rootState.auth.user.id
  if (transaction.ownerId && transaction.ownerId.startsWith('org_')) dispatch('auth/selectOrganization', { organizationId: transaction.ownerId }, { root: true })
  const result = await stelace.transactions.update(transaction.id, attrs)
  if (currentUserId && currentUserId.startsWith('org_')) dispatch('auth/selectOrganization', { organizationId: currentUserId }, { root: true })
  return result
}

// Combined fetchs
export async function fetchOfferData ({ dispatch, rootGetters }, offerId) {
  await dispatch('asset/read', { assetId: offerId }, { root: true })
  await dispatch('auth/selectOrganization', { organizationId: rootGetters['asset/asset'].ownerId }, { root: true })
}

export async function updateCompany (context, { asset, attrs }) {
  if (!asset) return null
  const currentUserId = context.rootState.auth.user.id
  if (asset.ownerId && asset.ownerId.startsWith('org_')) await context.dispatch('auth/selectOrganization', { organizationId: asset.ownerId }, { root: true })

  const assetUpdated = await stelace.assets.update(asset.id, attrs)
  // context.commit('auth/setUserCompany', { company: assetUpdated }, { root: true })

  if (currentUserId && currentUserId.startsWith('org_')) await context.dispatch('auth/selectOrganization', { organizationId: currentUserId }, { root: true })
  await context.dispatch('auth/getUserOrganizations', { fields: ['companies'] }, { root: true })
  return assetUpdated
}

// Copied from quasar, need refactoring
export async function duplicateAsset ({ dispatch }, { asset }) {
  const attrs = {
    active: false,
    validated: false,
    customAttributes: asset.customAttributes,
    assetTypeId: asset.assetTypeId,
    categoryId: asset.categoryId,
    description: asset.description,
    locations: asset.locations,
    metadata: asset.metadata,
    platformData: {
      duplicated: new Date(),
      reference: asset.id
    },
    name: asset.name,
  }
  if (attrs.locations.length > 1) attrs.locations = [get(attrs, 'locations[0]')]
  attrs.customAttributes.slug = await dispatch('asset/slugifyAsset', { name: asset.name }, { root: true })
  // Unwanted keys - because old or need reset
  for (const k of ['metadata._job.salaryUndefined', 'metadata.multicast', 'metadata._job.keepCompanyAdvantages']) set(attrs, k, undefined)
  return await stelace.assets.create(attrs)
}

export async function slugifyAsset (context, { name }) {
  const r = await stelace.forward.get(`/assets/slugify/${encodeURIComponent(name)}`)
  return get(r, 'slug', undefined)
}

export async function fetchAssetTypes ({ commit }) {
  const assetTypes = await stelace.assetTypes.list()
  commit('asset/setAssetTypes', { assetTypes }, { root: true })
  return assetTypes
}

export async function listOffers (context, params) {
  // For complete list of params see api
  return await stelace.forward.get('/users/offers', params)
}
